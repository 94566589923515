import $ from "jquery";
import React, { Component } from "react";
import { Dropdown, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import admin_image from "../../assets/img/costic/customer-6.jpg";
import logo from "../../assets/img/kayana/main-logo/logo.svg";
import * as ProfileServiceAPI from "../../services/profile/ProfileServiceAPI";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {
  logout,
  setPropertyId,
  setUsername,
  setVendor,
  setFranchise,
  setFranchises,
} from "../../redux/user/userSlice";
import {
  setMenuNames,
  setItems,
  setCategoryNames,
  setSelectedMenu,
} from "../../redux/menu/menuSlice";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import * as PaymentServiceAPI from "../../services/payment/PaymentServiceAPI";
import { isNotAuthorized } from "../../utils/functions";
import {
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
class Topnavigation extends Component {
  state = {
    name: "",
    profile_image_url: admin_image,
    business_trading_name: "",
    url: "",
    is_master_franchise: false,
    is_multi_vendor: false,
    selectedFranchise: this.props.propertyId || "",
    selectedVendor: this.props.propertyId || "",
    franchises: [],
    vendors: [],
    externalURL: "https://help.kayana.co.uk/",
    openSelect: null, // Track which select is open
    searchQuery: "",
  };

  componentDidMount = () => {
    localStorage.setItem("prop_id", this.props.propertyId);

    this.unlisten = this.props.history.listen((location) => {
      if (location.hash !== "#rep") {
        const screenWidth = window.innerWidth;
        if (screenWidth < 1000) {
          this.closeSideBar();
        }
      } else {
        location.hash = "";
      }
    });
    const { setCurrentUser } = this.props;
    const username = this.props.user.username
      ? this.props.user.username
      : localStorage.getItem("username");
    const business_trading_name = localStorage.getItem("business_trading_name");

    this.setState({
      username: username,
      business_trading_name: business_trading_name,
    });

    if (!(this.props.isFranchise || this.props.isVendor)) {
      this.fetchAccountInformation();
    } else if (this.props.mainPropertyId == this.props.propertyId) {
      this.fetchAccountInformation();
    }
    this.fetchFranchiseInformation();
    this.fetchVendorInformation();

    setTimeout(() => {
      if (
        isNotAuthorized(
          this.props?.location?.pathname,
          this.props?.user?.settings
        )
      ) {
        this.props.history.push({
          pathname: "/dashboard",
        });
      }
    }, 1000);

    ProfileServiceAPI.fetchProfile(username)
      .then((response) => {
        if (response.data.status) {
          if (response.data.data) {
            localStorage.setItem("user", JSON.stringify(response.data.data));
            this.setState({
              name: response.data.data.name,
              profile_image_url: response.data.data.profile_image_url,
            });
          }
        }
      })
      .catch(() => {
        // throw new Error(error)
      });
  };

  componentWillUnmount() {
    // Clean up the listener when the component unmounts
    this.unlisten();
  }
  handleChange = (event) => {
    this.setState({ selectedFranchise: event, anchorElFranchise: null });
    this.props.setPropertyId(event);
    const result = this.state.franchises.find(
      (item) => item.property_id === event
    );
    localStorage.setItem("business_name", result?.property_name);

    this.props.setUsername(result?.username || this.props.user.user.username);
    localStorage.setItem(
      "username",
      result?.username || this.props.user.user.username
    );

    this.props.setSelectedMenu(null);
    this.props.setMenuNames(null);
    this.props.setCategoryNames(null);
    this.props.setItems(null);
    if (window.location.pathname === "/orderdetails") {
      this.props.history.push("/orders-delivery/delivery");
    } else if (window.location.pathname === "/payoutdetails") {
      this.props.history.push("/payout");
    } else if (window.location.pathname === "/pay") {
      this.props.history.push("/manage-qr-flow");
    }
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  handleVendorChange = (event) => {
    this.setState({ selectedVendor: event, anchorEl: null });
    this.props.setPropertyId(event);
    const result = this.state.vendors.find(
      (item) => item.property_id === event
    );
    this.props.setUsername(result?.username || this.props.user.user.username);
    localStorage.setItem(
      "prop_id",
      result?.property_id || this.props.user.propertyId
    );

    localStorage.setItem(
      "username",
      result?.username || this.props.user.user.username
    );
    localStorage.setItem("business_name", result?.property_name);

    this.props.setUsername(result?.username);

    this.props.setSelectedMenu(null);
    this.props.setMenuNames(null);
    this.props.setCategoryNames(null);
    this.props.setItems(null);
    if (window.location.pathname === "/orderdetails") {
      this.props.history.push("/orders-delivery/delivery");
    } else if (window.location.pathname === "/payoutdetails") {
      this.props.history.push("/payout");
    } else if (window.location.pathname === "/pay") {
      this.props.history.push("/manage-qr-flow");
    }
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  handleSearchChange = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    this.setState({ searchQuery });

    // Filter vendors based on search query (case-insensitive)
    const filteredVendors = this.props.vendors?.filter((vendor) =>
      vendor?.property_name?.toLowerCase().includes(searchQuery)
    );

    this.setState({ filteredVendors });
  };

  fetchAccountInformation() {
    const dump = {
      property_id: this.props.propertyId,
      username: localStorage.getItem("username"),
    };

    PaymentServiceAPI.fetchAccountStatus(dump)
      .then((response) => {
        if (response.data.status) {
          this.setState({
            is_master_franchise: response.data.data.is_master_franchise,
            is_multi_vendor: response.data.data.is_multi_vendor,
            isLoading: false,
          });
          this.props.setFranchise(response.data.data.is_master_franchise);
          this.props.setVendor(response.data.data.is_multi_vendor);
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isLoading: false });
      });
  }

  fetchFranchiseInformation() {
    const dump = {
      property_id: this.props.mainPropertyId,
    };

    PaymentServiceAPI.fetchFranchiseInfomation(dump)
      .then((response) => {
        if (response.data.status) {
          this.setState({ franchises: response.data.data });
          this.props.setFranchises([
            ...response.data.data,
            {
              property_id: this.props.propertyId,
              property_name: this.props.user.user.business_trading_name,
            },
          ]);
        } else {
          this.setState({ franchises: [] });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ franchises: [] });
      });
  }

  fetchVendorInformation() {
    const dump = {
      property_id: this.props.mainPropertyId,
    };

    PaymentServiceAPI.fetchVendorInfomation(dump)
      .then((response) => {
        if (response.data.status) {
          this.setState({ vendors: response.data.data });
        } else {
          this.setState({ vendors: [] });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ vendors: [] });
      });
  }

  signout = () => {
    localStorage.clear();
    localStorage.removeItem("activeNavItem");
    this.props.logout();
    this.props.history.push("/");
  };

  addsidenavigation = () => {
    $(".ms-body").toggleClass("ms-aside-left-open");
    $("#ms-side-nav").toggleClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").toggleClass("d-block");
  };
  closeSideBar = () => {
    $(".ms-body").removeClass("ms-aside-left-open");
    $("#ms-side-nav").removeClass("ms-aside-open");
    $(".ms-aside-overlay.ms-overlay-left").removeClass("d-block");
  };

  topbaropen = () => {
    $("#ms-nav-options").toggleClass("ms-slide-down");
  };

  subscribeNotification = () => {};

  handleOpen = (selectId) => {
    this.setState({ openSelect: selectId });
  };
  handlePopoverOpen = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      anchorElFranchise: null,
      searchQuery: "",
    });
  };
  handlePopoverOpenFranchise = (event) => {
    this.setState({
      anchorElFranchise: event.currentTarget,
      anchorEl: null,
      searchQuery: "",
    });
  };

  handleClose = () => {
    this.setState({
      openSelect: null,
      anchorEl: null,
      anchorElFranchise: null,
    });
  };
  handleDropdownOpen = () => {
    this.setState({ openSelect: true });
  };

  handleDropdownClose = () => {
    this.setState({ openSelect: false });
  };

  render() {
    const {
      externalURL,
      franchises,
      vendors,
      searchQuery,
      selectedVendor,
      anchorEl,
      anchorElFranchise,
      selectedFranchise,
    } = this.state;
    const open = Boolean(anchorEl);
    const openFranchise = Boolean(anchorElFranchise);
    const filteredVendors = vendors
      ? vendors?.filter((vendor) =>
          vendor?.property_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      : [];
    const filteredFranchises = franchises
      ? franchises?.filter((franchise) =>
          franchise?.property_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      : [];
    const selectedVendorName =
      this.props.user.user.property_id === selectedVendor
        ? this.props.user.user.business_trading_name
        : vendors?.find((v) => v.property_id === selectedVendor)
            ?.property_name || "";
    const selectedFranchiseName =
      this.props.user.user.property_id === selectedFranchise
        ? this.props.user.user.business_trading_name
        : franchises?.find((v) => v.property_id === selectedFranchise)
            ?.property_name || "";
    return (
      <nav className="navbar ms-navbar">
        <div
          className="ms-aside-toggler ms-toggler pl-0"
          onClick={this.addsidenavigation}
        >
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
        </div>
        <div className="logo-sn logo-sm ms-d-block-sm">
          <Link
            className="pl-0 ml-0 text-center navbar-brand mr-0"
            to="/dashboard"
          >
            <img src={logo} />{" "}
          </Link>
        </div>
        <ul id="ms-nav-options" className="ms-nav-list ms-inline mb-0">
          <li className="ms-nav-item dropdown">
            {this.props.isFranchise && (
              <FormControl fullWidth sx={{ width: 170, zIndex: 9999 }}>
                <InputLabel id="property_id">Select Franchise</InputLabel>
                <OutlinedInput
                  id="property_id"
                  label="Select Franchise"
                  value={selectedFranchiseName}
                  readOnly
                  onClick={this.handlePopoverOpenFranchise}
                  endAdornment={<KeyboardArrowDownIcon />}
                  sx={{ cursor: "pointer", background: "white" }}
                />
              </FormControl>
            )}

            {/* Popover for Vendor Selection */}
            <Popover
              open={openFranchise}
              anchorEl={anchorElFranchise}
              onClose={this.handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <List sx={{ width: 300, maxHeight: 250, overflowY: "auto" }}>
                {/* Search Field */}
                <ListItem disablePadding>
                  <TextField
                    variant="outlined"
                    placeholder="Search franchise..."
                    fullWidth
                    size="small"
                    value={searchQuery}
                    onChange={this.handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ pl: 2, pb: 1, pr: 2 }}
                  />
                </ListItem>

                {/* Current User's Vendor */}
                <ListItem
                  button
                  selected={
                    selectedFranchise === this.props.user.user.property_id
                  }
                  onClick={() =>
                    this.handleChange(this.props.user.user.property_id)
                  }
                >
                  <ListItemText
                    primary={this.props.user.user.business_trading_name}
                  />
                </ListItem>

                {/* Filtered franchise List */}
                {filteredFranchises?.length > 0 ? (
                  filteredFranchises?.map((item) => (
                    <ListItem
                      button
                      key={item.property_id}
                      selected={selectedFranchise === item.property_id}
                      onClick={() => this.handleChange(item.property_id)}
                    >
                      <ListItemText primary={item.property_name} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem disabled>
                    <ListItemText primary="No franchise with such name" />
                  </ListItem>
                )}
              </List>
            </Popover>
          </li>
          <li className="ms-nav-item dropdown">
            {this.props.isVendor && (
              <FormControl fullWidth sx={{ width: 170, zIndex: 9999 }}>
                <InputLabel id="property_id">Select Vendor</InputLabel>
                <OutlinedInput
                  id="property_id"
                  label="Select Vendor"
                  value={selectedVendorName}
                  readOnly
                  onClick={this.handlePopoverOpen}
                  endAdornment={<KeyboardArrowDownIcon />}
                  sx={{ cursor: "pointer", background: "white" }}
                />
              </FormControl>
            )}

            {/* Popover for franchise Selection */}
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={this.handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <List sx={{ width: 300, maxHeight: 250, overflowY: "auto" }}>
                {/* Search Field */}
                <ListItem disablePadding>
                  <TextField
                    variant="outlined"
                    placeholder="Search vendor..."
                    fullWidth
                    size="small"
                    value={searchQuery}
                    onChange={this.handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ pl: 2, pb: 1, pr: 2 }}
                  />
                </ListItem>

                {/* Current User's Vendor */}
                <ListItem
                  button
                  selected={selectedVendor === this.props.user.user.property_id}
                  onClick={() =>
                    this.handleVendorChange(this.props.user.user.property_id)
                  }
                >
                  <ListItemText
                    primary={this.props.user.user.business_trading_name}
                  />
                </ListItem>

                {/* Filtered Vendor List */}
                {filteredVendors.length > 0 ? (
                  filteredVendors.map((item) => (
                    <ListItem
                      button
                      key={item.property_id}
                      selected={selectedVendor === item.property_id}
                      onClick={() => this.handleVendorChange(item.property_id)}
                    >
                      <ListItemText primary={item.property_name} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem disabled>
                    <ListItemText primary="No vendors with such name" />
                  </ListItem>
                )}
              </List>
            </Popover>
          </li>
          <li
            className="ms-nav-item ms-nav-user dropdown"
            style={{ zIndex: 9999 }}
          >
            <Dropdown className="custom-dropdown">
              <Dropdown.Toggle as={NavLink} id="userDropdown" className="p-0">
                {this.state.profile_image_url ? (
                  <img
                    className="ms-user-img ms-img-round"
                    src={this.state.profile_image_url}
                  />
                ) : (
                  <img className="ms-user-img ms-img-round" src={admin_image} />
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu
                className="dropdown-menu dropdown-menu-right user-dropdown"
                aria-labelledby="userDropdown"
              >
                <div className="dropdown-menu-header">
                  <h6 className="dropdown-header ms-inline m-0">
                    <span className="text-disabled">
                      {" "}
                      {this.state.name ? "Welcome, " + this.state.name : ""}
                    </span>
                  </h6>
                </div>
                <div className="dropdown-divider" />
                <div className="ms-dropdown-list">
                  <Link className="media fs-14 p-2" to="/user-profiles">
                    <span>
                      <i className="fa flaticon-user mr-2" />
                      User Profile
                    </span>
                  </Link>

                  <Link className="media fs-14 p-2" to="/setting">
                    <span>
                      <i className="fa flaticon-gear mr-2" /> Account Settings
                    </span>
                  </Link>
                </div>
                <div className="dropdown-divider" />

                <div className="dropdown-menu-footer">
                  <Link
                    className="media fs-14 p-2"
                    to=""
                    onClick={this.signout}
                  >
                    {" "}
                    <span>
                      <i className="fa flaticon-shut-down mr-2" /> Logout
                    </span>
                  </Link>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="ms-nav-item">
            <a
              className="media fs-14 p-2"
              href={externalURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
                <i className="far fa-question-circle fa-lg mr-2" /> Help
              </span>
            </a>
          </li>
        </ul>
        <div
          className="ms-toggler ms-d-block-sm pr-0 ms-nav-toggler"
          onClick={this.topbaropen}
        >
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
          <span className="ms-toggler-bar bg-primary" />
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  propertyId: state.user.propertyId,
  mainPropertyId: state.user.mainPropertyId,
  isFranchise: state.user.isFranchise,
  isVendor: state.user.isVendor,
});

// const mapDispatchToProps = (dispatch) => ({
// setCurrentUser: (user) => dispatch(setCurrentUser(user)),

// });

const mapDispatchToProps = {
  logout,
  setPropertyId,
  setUsername,
  setFranchise,
  setVendor,
  setMenuNames,
  setCategoryNames,
  setItems,
  setSelectedMenu,
  setFranchises,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Topnavigation))
);
