import React, { lazy, Suspense } from "react";
import { Switch, Redirect, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Addproduct from "../../components/sections/Addproduct/Addproductcontent";

import PrivateRoutes from "../_PrivateRoutes";
import PublicRoutes from "../_PublicRoute";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop } from "@mui/material";

// Routes of component

const Menu = lazy(() => import("../../components/sections/Menu"));
const MenuGrid = lazy(() =>
  import("../../components/sections/Menugrid/Gridcontent")
);
const MenuList = lazy(() =>
  import("../../components/sections/Menulist/Listcontent")
);
const OrderDetails = lazy(() =>
  import("../../components/sections/OrderDetails/OrderDetails")
);
// const Orders = lazy(() =>
//   import("../../components/sections/Orders/Ordertable")
// );
const Orders = lazy(() => import("./../../views/Orders/Order"));
const Disputes = lazy(() =>
  import("../../components/sections/disputes/disputes")
);

const DisputeDetails = lazy(() =>
  import("../../components/sections/disputes/disputeDetails/DisputeDetails")
);
const Payout = lazy(() => import("../../components/sections/Payout/Content"));
const PayoutDetails = lazy(() =>
  import("../../components/sections/Payoutdetails/Detailcontent")
);
const TransactionDetails = lazy(() =>
  import("../../components/sections/Tansactiondetails/Detailcontent")
);
const Transaction = lazy(() =>
  import("../../components/sections/Transaction/Content")
);
const Item = lazy(() => import("../../components/sections/items/Content"));
const UnSold = lazy(() => import("../../components/sections/sales/Content"));
const TopSell = lazy(() =>
  import("../../components/sections/topsales/Content")
);
const ZReport = lazy(() => import("../../components/sections/zreport/Content"));
const DataReport = lazy(() =>
  import("../../components/sections/reports/dataReport/Content")
);
const DogDollarReport = lazy(() =>
  import("../../components/sections/reports/dogDollarCorrection/Content")
);
const GrossSalesStoreReport = lazy(() =>
  import("../../components/sections/reports/SalesStore/Content")
);

const PaymentMethodReport = lazy(() =>
  import("../../components/sections/reports/PaymentMethod/Content")
);
const SalesSummaryReport = lazy(() =>
  import("../../components/sections/reports/salesSummary/Content")
);
const TotalReport = lazy(() =>
  import("../../components/sections/reports/total/Content")
);
const CharlieReport = lazy(() =>
  import("../../components/sections/reports/charlie/Content")
);
const ItemReport = lazy(() =>
  import("../../components/sections/itemReport/Content")
);
const WeeklyBusinessReport = lazy(() =>
  import("../../components/sections/weeklyBusinessReport/Content")
);
const PLUSalesReport = lazy(() =>
  import("../../components/sections/pluReport/Content")
);
const ActivityReport = lazy(() =>
  import("../../components/sections/activityReport/Content")
);
const SummaryReport = lazy(() =>
  import("../../components/sections/summaryReport/Content")
);
const Trending = lazy(() =>
  import("../../components/sections/trending/Content")
);
const Tax = lazy(() => import("../../container/tax"));
const General = lazy(() => import("../general"));
const TableManagement = lazy(() =>
  import("../../components/sections/TableManagement/TableManagement")
);
const Notification = lazy(() => import("../../container/notifications"));
const Theme = lazy(() => import("../../container/themes"));
const NotificationSetting = lazy(() =>
  import("../../container/notificationSetting")
);
const Promotions = lazy(() => import("../../container/promotions"));
const TakeAwayOrder = lazy(() => import("../../container/TakeAwayOrder"));
const DineinOrder = lazy(() => import("../../container/DineinOrder"));
const DeliveryToPark = lazy(() => import("../../container/DeliveryToPark"));
const DonationOrder = lazy(() => import("../../container/DonationOrder"));
const Shift = lazy(() => import("../../container/shift"));
const Suite = lazy(() => import("../../container/suite"));
const BookedSuite = lazy(() => import("../../container/booSuite"));
const Login = lazy(() => import("../login"));
const TermsAndConditions = lazy(() =>
  import("../../container/terms-and-condtions/index")
);
const SignUp = lazy(() => import("../SignUp/"));
const Payment = lazy(() => import("../payment/index"));
const Grants = lazy(() => import("../cashAdvance/index"));
const GrantTerms = lazy(() =>
  import("../cashAdvance/terms-and-condtions/index")
);
const GrantAccept = lazy(() => import("../cashAdvance/accept/index"));
const GrantDetails = lazy(() => import("../cashAdvance/details/index"));
const Receipt = lazy(() => import("../receipt/index"));

const Product = lazy(() => import("../../components/sections/product"));
const Shop = lazy(() => import("../../components/sections/shop"));
const ShopItemDetails = lazy(() =>
  import("../../components/sections/shop/ShopItemDetails")
);
const Cart = lazy(() => import("../../components/sections/shop/Cart"));
const Discount = lazy(() => import("../discount"));
const EposDiscount = lazy(() => import("../eposDiscounts"));
const DeliveryCharges = lazy(() => import("../deliveryCharges"));
const ManageUser = lazy(() => import("../manageUsers"));
const HappyHourDiscount = lazy(() => import("../happyHourDiscount"));
const Room = lazy(() => import("../room"));
const Table = lazy(() => import("../table"));
const GenerateQRCode = lazy(() => import("../generateQR"));
const ManageQR = lazy(() => import("../manage-qr"));
const QRFlow = lazy(() => import("../manage-qr-flow"));
const Pricing = lazy(() => import("../Pricing"));
const Pay = lazy(() => import("../pay"));
const Approved = lazy(() => import("../../container/cashAdvance/approved"));
const EnableWebOrdering = lazy(() => import("../webOrdering/index"));
const EnableWebOrderingAccept = lazy(() =>
  import("../webOrdering/accept/index")
);
const CreateQRFlow = lazy(() =>
  import("../../components/sections/steps/HorizontalNonLinearStepper")
);

const PayByLink = lazy(() =>
  import("../../components/sections/payByLink/Content")
);
const PayByLinkTransactionDetails = lazy(() =>
  import("../../components/sections/payByLinkTransactionDetails/Detailcontent")
);
const PayByLinkTransactions = lazy(() =>
  import(
    "../../components/sections/payByLinkTransactionDetails/Tansactiondetails/Detailcontent"
  )
);
const SplitPayment = lazy(() => import("../SplitPayment"));
const UserProfile = lazy(() => import("../Profile"));
const Settings = lazy(() => import("../settings/Content"));
const AllDiscounts = lazy(() => import("../settings/discounts/Content"));
const Guide = lazy(() => import("../guide/Content"));
const CreateNewPassword = lazy(() =>
  import("../../container/ForcePasswordChange")
);
const AppSetting = lazy(() => import("../AppSettings/AppSetting"));
const AppTiming = lazy(() => import("../../views/Timings/Index"));
const TableReservationUpdatePage = lazy(() =>
  import("../TableReservationUpdatePage")
);
const Gallery = lazy(() =>
  import("../../components/sections/Menu/dialogs/ReactMediaLibraryWrapper")
);
const index = lazy(() => import("../../container/finance/Index"));

const Nash = lazy(() => import("../NashOrdering/Nash"));
const nashHome = lazy(() => import("../NashOrdering/Index"));
const DeliverictActivate = lazy(() => import("../eposNow/deliverictActivate"));
const DeliverictTerms = lazy(() =>
  import("../eposNow/deliverictActivate/terms")
);
const NashOrder = lazy(() => import("../NashOrdering/NashOrder"));
const EposNow = lazy(() => import("views/Integrations"));
const Tip = lazy(() => import("../tip"));
const EposActivate = lazy(() => import("../eposNow/accept"));
const InventoryReason = lazy(() =>
  import("../../container/inventory/reason/AddBatchReason")
);

const Inventory = lazy(() => import("../../container/inventory/Inventory"));
const InventorygAccept = lazy(() => import("../../container/inventory/accept"));
const EnableInventory = lazy(() => import("../../container/inventory"));
const ScreenSaverTerms = lazy(() => import("../../container/tip/accept"));
const ScreenSaverPay = lazy(() => import("../../container/tip/pay/index"));
const tagging = lazy(() => import("../ItemTagging"));

const FloorPlannerWrapper = lazy(() =>
  import("../../container/floor-table/FloorPlannerWrapper")
);

const Homecontent = lazy(() =>
  import("../../components/sections/Dashboard/Homecontent")
);

const Setup = lazy(() =>
  import("../../components/sections/Dashboard/Step/Main")
);

const MenuInfoReport = lazy(() =>
  import("../../components/sections/MenuInfoReport/index")
);
const CheckoutSection = lazy(() =>
  import("../../views/CheckoutSection/CheckoutSection")
);

const Compliance = lazy(() => import("views/Compliance"));
const Customers = lazy(() => import("views/Customers"));
const EditCompliance = lazy(() => import("views/Compliance/EditCompliance"));
const CustomerOrderDetails = lazy(() =>
  import("views/Customers/CustomerOrderDetails")
);
const discountUsage = lazy(() =>
  import("../../views/Reports/DiscountUsageReport")
);

const CashFloat = lazy(() => import("../../views/Reports/CashFloatReport"));
const EnableMfa = lazy(() => import("../../views/MultiFactorEnable/Index"));
const settingTwoFactorAuth = lazy(() =>
  import("../../views/SettingsTwoFactorAuth/Index")
);

function Routes() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <Suspense
      fallback={
        <Backdrop sx={(theme) => ({ color: "#fff", zIndex: 999 })} open={true}>
          <CircularProgress color="primary" />
        </Backdrop>
      }
    >
      <Switch>
        {isLoggedIn && (
          <PrivateRoutes path="/dashboard" component={Homecontent} />
        )}
        {isLoggedIn && <PrivateRoutes path="/setup" component={Setup} />}

        {isLoggedIn && (
          <PrivateRoutes path="/table-management" component={TableManagement} />
        )}

        {isLoggedIn && <PrivateRoutes path="/setting" component={Settings} />}
        {isLoggedIn && <PrivateRoutes path="/item-tags" component={tagging} />}

        {isLoggedIn && (
          <PrivateRoutes path="/all-discounts" component={AllDiscounts} />
        )}
        {isLoggedIn && (
          <PrivateRoutes path="/checkout-section" component={CheckoutSection} />
        )}

        {isLoggedIn && <PrivateRoutes path="/guide" component={Guide} />}
        {isLoggedIn && (
          <PrivateRoutes path="/cash-float" component={CashFloat} />
        )}
        {isLoggedIn && (
          <PrivateRoutes path="/discount-usage" component={discountUsage} />
        )}
        {isLoggedIn && (
          <PrivateRoutes
            path="/enable-two-factor-authentication"
            component={settingTwoFactorAuth}
          />
        )}
        {isLoggedIn && <PrivateRoutes path="/general" component={General} />}

        {isLoggedIn && <PrivateRoutes path="/general" component={General} />}

        {isLoggedIn && <PrivateRoutes path="/payment" component={Payment} />}

        {isLoggedIn && <PrivateRoutes path="/grants" component={Grants} />}

        {isLoggedIn && (
          <PrivateRoutes
            path="/capital-terms-conditions"
            component={GrantTerms}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/grant-accept" component={GrantAccept} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/grant-details" component={GrantDetails} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/discount" component={EposDiscount} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/epos-mpos-discounts" component={EposDiscount} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/delivery-charges" component={DeliveryCharges} />
        )}
        {isLoggedIn && <PrivateRoutes path="/shift" component={Shift} />}

        {isLoggedIn && <PrivateRoutes path="/suite" component={Suite} />}

        {isLoggedIn && (
          <PrivateRoutes path="/booked-suite" component={BookedSuite} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/manage-user" component={ManageUser} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/happy-hour-discount"
            component={HappyHourDiscount}
          />
        )}

        {isLoggedIn && <PrivateRoutes path="/room" component={Room} />}

        {isLoggedIn && <PrivateRoutes path="/table" component={Table} />}

        {isLoggedIn && (
          <PrivateRoutes path="/generate-qr-code" component={GenerateQRCode} />
        )}

        {isLoggedIn && <PrivateRoutes path="/manage-qr" component={ManageQR} />}

        {isLoggedIn && (
          <PrivateRoutes path="/manage-qr-flow" component={QRFlow} />
        )}

        {isLoggedIn && <PrivateRoutes path="/pricing" component={Pricing} />}

        {isLoggedIn && (
          <PrivateRoutes path="/create-qr-flow" component={CreateQRFlow} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/pay-by-link" component={PayByLink} />
        )}

        {isLoggedIn && <PrivateRoutes path="/pay" component={Pay} />}

        {isLoggedIn && <PrivateRoutes path="/approved" component={Approved} />}

        {isLoggedIn && (
          <PrivateRoutes path="/web-order" component={EnableWebOrdering} />
        )}
        {isLoggedIn && (
          <PrivateRoutes path="/nash-order" component={NashOrder} />
        )}
        {isLoggedIn && <PrivateRoutes path="/nash" component={Nash} />}
        {isLoggedIn && <PrivateRoutes path="/nashHome" component={nashHome} />}

        {isLoggedIn && <PrivateRoutes path="/epos-now" component={EposNow} />}

        {isLoggedIn && (
          <PrivateRoutes path="/epos-activate" component={EposActivate} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/web-order-accept"
            component={EnableWebOrderingAccept}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/inventory-accept"
            component={InventorygAccept}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/screen-saver-terms-conditions"
            component={ScreenSaverTerms}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/screensaver-pay" component={ScreenSaverPay} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/enable-inventory" component={EnableInventory} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/pay-by-link-transactions"
            component={PayByLinkTransactionDetails}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/pay-by-link-transaction-details"
            component={PayByLinkTransactions}
          />
        )}

        {isLoggedIn && <PrivateRoutes path="/receipt" component={Receipt} />}

        {isLoggedIn && <PrivateRoutes path="/products" component={Product} />}

        {isLoggedIn && <PrivateRoutes path="/shop" component={Shop} />}

        {isLoggedIn && (
          <PrivateRoutes path="/shop-details" component={ShopItemDetails} />
        )}

        {isLoggedIn && <PrivateRoutes path="/cart" component={Cart} />}

        {isLoggedIn && (
          <PrivateRoutes path="/splitpayment" component={SplitPayment} />
        )}

        {isLoggedIn && <PrivateRoutes path="/billing" component={index} />}

        {isLoggedIn && (
          <PrivateRoutes path="/transactions" component={Transaction} />
        )}

        {isLoggedIn && <PrivateRoutes path="/sales" component={Item} />}

        {isLoggedIn && (
          <PrivateRoutes path="/non-selling-items" component={UnSold} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/top-selling-items" component={TopSell} />
        )}

        {isLoggedIn && <PrivateRoutes path="/zreport" component={ZReport} />}

        {isLoggedIn && (
          <PrivateRoutes path="/item-report" component={ItemReport} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/weekly-business-report"
            component={WeeklyBusinessReport}
          />
        )}
        {isLoggedIn && (
          <PrivateRoutes path="/menu-info-report" component={MenuInfoReport} />
        )}
        {isLoggedIn && (
          <PrivateRoutes path="/plu-sales-report" component={PLUSalesReport} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/activity-report" component={ActivityReport} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/summary-report" component={SummaryReport} />
        )}

        {isLoggedIn && <PrivateRoutes path="/xreport" component={Trending} />}

        {isLoggedIn && (
          <PrivateRoutes path="/payoutdetails" component={PayoutDetails} />
        )}
        {isLoggedIn && <PrivateRoutes path="/payout" component={Payout} />}
        {isLoggedIn && (
          <PrivateRoutes
            path="/transactiontdetails"
            component={TransactionDetails}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/orders-dine-in" component={DineinOrder} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/orders-takeaway" component={TakeAwayOrder} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/orders-delivery/:type" component={Orders} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/orderdetails" component={OrderDetails} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/donation-order" components={DonationOrder} />
        )}

        {isLoggedIn && <PrivateRoutes path="/disputes" component={Disputes} />}
        {isLoggedIn && (
          <PrivateRoutes path="/customers" component={Customers} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/dispute-details" component={DisputeDetails} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/orders-delivery-to-park"
            component={DeliveryToPark}
          />
        )}

        {isLoggedIn && <PrivateRoutes path="/tax" component={Tax} />}

        {isLoggedIn && (
          <PrivateRoutes path="/notification" component={Notification} />
        )}

        {isLoggedIn && <PrivateRoutes path="/theme" component={Theme} />}

        {isLoggedIn && <PrivateRoutes path="/tip" component={Tip} />}

        {isLoggedIn && (
          <PrivateRoutes
            path="/notification-setting"
            component={NotificationSetting}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/user-profiles" component={UserProfile} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/add-product/:category_id"
            component={Addproduct}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/addproduct/:menu_id/:category_id/:is_buffet"
            component={Addproduct}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/editProduct/:menu_id/:category_id/:item_id/:is_buffet"
            component={Addproduct}
          />
        )}

        {isLoggedIn && <PrivateRoutes path="/menu" component={Menu} />}

        {isLoggedIn && (
          <PrivateRoutes path="/menu-grid/:category_id" component={MenuGrid} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/menuGrid/:menu_id/:category_id"
            component={MenuGrid}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/menu-list/:menu_id" component={MenuList} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/promotions" component={Promotions} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/app-setting" component={AppSetting} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/app-timing" component={AppTiming} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/table-reservation-update-page"
            component={TableReservationUpdatePage}
          />
        )}
        {isLoggedIn && (
          <PrivateRoutes path="/inventory" component={Inventory} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/inventory-reasons"
            component={InventoryReason}
          />
        )}

        {isLoggedIn && <PrivateRoutes path="/gal" component={Gallery} />}

        {isLoggedIn && (
          <PrivateRoutes path="/reports/data-report" component={DataReport} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/reports/dog-dollar-correction"
            component={DogDollarReport}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/reports/total" component={TotalReport} />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/reports/charlie" component={CharlieReport} />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/reports/gross-sales-by-store"
            component={GrossSalesStoreReport}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/reports/payment-method"
            component={PaymentMethodReport}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/reports/sales-summary"
            component={SalesSummaryReport}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/delevirict-activate"
            component={DeliverictActivate}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes
            path="/delevirict-terms-and-conditions"
            component={DeliverictTerms}
          />
        )}

        {isLoggedIn && (
          <PrivateRoutes path="/floor-layout" component={FloorPlannerWrapper} />
        )}

        {isLoggedIn && (
          <PrivateRoutes exact path="/compliance" component={Compliance} />
        )}
        {isLoggedIn && (
          <PrivateRoutes
            path="/compliance/:item_id/edit"
            component={EditCompliance}
          />
        )}
        {isLoggedIn && (
          <PrivateRoutes
            path="/view-customers/:kumUserName"
            component={CustomerOrderDetails}
          />
        )}
        <PublicRoutes exact path="/" component={Login} />
        <PublicRoutes path="/login" component={Login} />
        <PublicRoutes path="/pbl-tnc" component={TermsAndConditions} />
        <PublicRoutes path="/sign-up" component={SignUp} />
        <PublicRoutes path="/two-factor-authentication" component={EnableMfa} />

        <PublicRoutes
          path="/create-new-password"
          component={CreateNewPassword}
        />
        <Redirect from="*" to="/" />
      </Switch>
    </Suspense>
  );
}

export default withRouter(Routes);
